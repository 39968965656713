import { fetchCustomerFavicon } from '@hkm/features/app/features/branding/commands/api/fetchCustomerFavicon';
import { fetchPropertyFavicon } from '@hkm/features/app/features/branding/commands/api/fetchPropertyFavicon';

import { BrandingFaviconSettingValue } from '@ac/library-api/dist/businessContext/customer/settings/model/general/brandingFavicon';

interface Props {
  propertyId: string | undefined;
  propertyBrandingFavicon: BrandingFaviconSettingValue | undefined;
  customerBrandingFavicon: BrandingFaviconSettingValue | undefined;
}

export async function fetchFavicon({
  propertyId,
  propertyBrandingFavicon,
  customerBrandingFavicon,
}: Props): Promise<{ href: Blob; type: string | undefined } | undefined> {
  const propertyFavicon = propertyBrandingFavicon
    ? await fetchPropertyFavicon({ propertyId })
    : undefined;

  if (propertyFavicon) {
    return {
      href: propertyFavicon,
      type: propertyBrandingFavicon?.contentType,
    };
  }

  const customerFavicon = customerBrandingFavicon
    ? await fetchCustomerFavicon()
    : undefined;

  if (customerFavicon) {
    return {
      href: customerFavicon,
      type: customerBrandingFavicon?.contentType,
    };
  }

  return undefined;
}
