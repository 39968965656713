/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectBusinessDate } from '@hkm/components/Menu/PropertySelector/domain/selectors';
import MaintenanceStateBadge from '@hkm/components/shared/MaintenanceStateBadge/MaintenanceStateBadge';
import MaintenanceStatusBadge from '@hkm/components/shared/MaintenanceStatusBadge/MaintenanceStatusBadge';
import MaintenanceDetailsFormBody from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/MaintenanceDetailsTileForm';
import { MaintenanceUpdateFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/models/maintenanceFormData';
import { MaintenanceUpdateFieldDisability } from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/models/MaintenanceUpdateFieldDisability';
import { mapToMaintenanceFormData } from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/models/mappers/mapToMaintenanceFormData';
import { mapToUpdateMaintenance } from '@hkm/components/shared/Templates/Maintenance/MaintenanceDetailsTile/models/mappers/mapToUpdateMaintenance';
import {
  HkmValidator,
  ValidationStatuses,
} from '@hkm/shared/interfaces/validationStatuses';
import { Validator } from '@hkm/shared/validation/validator';
import {
  isDateNotEmpty,
  isEndDateBeforeStartDate,
  isNonEmptyString,
  isStartDateAfterEndDate,
  isStartDateBeforeBusinessDate,
} from '@hkm/shared/validation/validators';
import { UnifiedMaintenanceDetails } from '@hkm/types/maintenance/models/UnifiedMaintenanceDetails';
import { UnifiedRoomDetails } from '@hkm/types/room/model/UnifiedRoomDetails';
import { clone, isEqual } from 'lodash';

import {
  RoomMaintenanceState,
  RoomMaintenanceStatus,
  UpdateMaintenances,
} from '@ac/library-api';
import {
  AcTile,
  AcTileVariant,
} from '@ac/mobile-components/dist/components/tile';
import { Styleable } from '@ac/mobile-components/dist/interfaces/componentProps';
import { formatTestSelector } from '@ac/mobile-components/dist/utils';
import { Form, ValidationSchema } from '@ac/react-infrastructure';
import {
  FlexDirection,
  FlexGap,
  IconName,
  Orientation,
} from '@ac/web-components';

export interface MaintenanceDetailsTileProps extends Styleable {
  readonly?: boolean;
  variant?: AcTileVariant;
  hideStateBadge?: boolean;
  unifiedRoomDetails: UnifiedRoomDetails;
  maintenanceDetails?: UnifiedMaintenanceDetails;
  disableFields?: MaintenanceUpdateFieldDisability;
  onSubmit?(formData: UpdateMaintenances): void;
}

interface Statuses extends ValidationStatuses {
  fromTime: HkmValidator;
  toTime: HkmValidator;
  statusCode: HkmValidator;
  reasonCode: HkmValidator;
  comment: HkmValidator;
  returnStatusCode: HkmValidator;
}

const Schema: ValidationSchema<Statuses> = {
  fromTime: [
    isDateNotEmpty,
    isStartDateAfterEndDate,
    isStartDateBeforeBusinessDate,
  ],
  toTime: [isDateNotEmpty, isEndDateBeforeStartDate],
  comment: [isNonEmptyString],
};

const MaintenanceDetailsTile: FC<MaintenanceDetailsTileProps> = (
  props: MaintenanceDetailsTileProps
) => {
  const { t } = useTranslation();
  const businessDate = useSelector(selectBusinessDate) ?? '';
  const initialData: MaintenanceUpdateFormData = mapToMaintenanceFormData(
    businessDate,
    props.unifiedRoomDetails,
    props.maintenanceDetails
  );
  const previousFormState: MaintenanceUpdateFormData = clone(initialData);
  const isMaintenanceActive =
    props.maintenanceDetails?.state?.code === RoomMaintenanceState.Active;
  const ignoreFields = isMaintenanceActive ? ['fromTime'] : [];
  const testSelectorPrefix = 'maintenance-details';

  const [validator] = useState<Validator<MaintenanceUpdateFormData, Statuses>>(
    new Validator(Schema, ignoreFields)
  );
  const validate = useCallback(
    (values: MaintenanceUpdateFormData) => validator.validate(values),
    [validator]
  );

  const onSubmit = (formData: MaintenanceUpdateFormData) => {
    if (props.onSubmit && !isEqual(previousFormState, formData)) {
      props.onSubmit(mapToUpdateMaintenance(formData));
    }
  };

  return (
    <AcTile
      title={t('ROOM_DETAILS.MAINTENANCE')}
      icon={IconName.eod}
      className={props.className}
      style={props.style}
      variant={props.variant}
    >
      <ac-flex direction={FlexDirection.column} rowGap={FlexGap.md}>
        <ac-flex columnGap={FlexGap.md}>
          {props.maintenanceDetails?.status && (
            <ac-text-group
              label={t('ROOM_DETAILS.MAINTENANCE_STATUS')}
              orientation={Orientation.vertical}
              data-test-selector={formatTestSelector(
                testSelectorPrefix,
                'maintenanceStatus'
              )}
            >
              <MaintenanceStatusBadge
                status={
                  props.maintenanceDetails.status.code as RoomMaintenanceStatus
                }
                fullTextInTile={true}
              />
            </ac-text-group>
          )}

          {props.maintenanceDetails?.state && !props.hideStateBadge && (
            <ac-text-group
              label={t('ROOM_DETAILS.MAINTENANCE_STATE')}
              orientation={Orientation.vertical}
              data-test-selector={formatTestSelector(
                testSelectorPrefix,
                'maintenanceState'
              )}
            >
              <MaintenanceStateBadge
                status={
                  props.maintenanceDetails.state.code as RoomMaintenanceState
                }
              />
            </ac-text-group>
          )}
        </ac-flex>

        {props.maintenanceDetails?.reason && (
          <ac-text-group
            label={t('ROOM_DETAILS.REASON')}
            orientation={Orientation.vertical}
            data-test-selector={formatTestSelector(
              testSelectorPrefix,
              'reason'
            )}
          >
            {t('ROOM_DETAILS.REASON_TYPE_FORMAT', {
              code: props.maintenanceDetails.reason.code,
              description: props.maintenanceDetails.reason.description,
            })}
          </ac-text-group>
        )}

        <Form
          initialValues={initialData}
          validate={validate as never}
          onSubmit={onSubmit}
        >
          {(formRenderProps) => (
            <MaintenanceDetailsFormBody
              formProps={formRenderProps}
              readonly={props.readonly}
              disableFields={props.disableFields}
            />
          )}
        </Form>
      </ac-flex>
    </AcTile>
  );
};

export default memo(MaintenanceDetailsTile);
