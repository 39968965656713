export enum MobileHeaderType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface PrimaryMobileHeader {
  type: MobileHeaderType.Primary;
}

export interface SecondaryMobileHeader {
  type: MobileHeaderType.Secondary;
  onBackRedirection: () => void;
}

export interface MobileHeaderProps {
  level?: PrimaryMobileHeader | SecondaryMobileHeader;
  title?: string;
}
