import React from 'react';
import HeaderCloseButton from '@hkm/components/shared/LayoutComponents/HeaderCloseButton';
import HeaderHelpButton from '@hkm/components/shared/LayoutComponents/HeaderHelpButton';
import { useMicroFrontendsContext } from '@hkm/features/microFrontends/store/context';

/**
 * @deprecated
 */
export const NavbarRight = (): JSX.Element | null => {
  const {
    state: { navBar },
  } = useMicroFrontendsContext();

  if (!navBar) {
    return null;
  }

  return (
    <>
      {navBar.showHelpIcon && <HeaderHelpButton />}
      {(navBar.closeConfig?.link || navBar.closeConfig?.onClickCallback) && (
        <HeaderCloseButton
          link={navBar.closeConfig.link}
          onClick={navBar.closeConfig.onClickCallback}
        />
      )}
    </>
  );
};
