interface Favicon {
  href: Blob;
  type: string | undefined;
}

export function updateFaviconInDOM(selector: string, favicon: Favicon): void {
  const href = URL.createObjectURL(favicon.href);
  const element = document.querySelector(selector);

  element?.setAttribute('href', href);

  if (favicon.type) {
    element?.setAttribute('type', favicon.type);
  }
}
