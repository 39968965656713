/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import React, { useEffect } from 'react';
import { ErrorPage, ErrorPageType } from '@hkm/features/errors/pages/Error';
import { MicroFrontendContainer } from '@hkm/features/microFrontends/pages/container/features/container/Container';
import { Console } from '@hkm/utils/console';

import { FeatureReport } from '@ac/library-api/dist/businessContext/featureResolver/featureRequirements';

interface Props {
  feature: FeatureReport;
}

export const MicroFrontendPage = ({ feature }: Props): JSX.Element => {
  useEffect(() => {
    if (!feature.isEnabled) {
      void Console.info('MICRO_FRONTEND: Module Access Denied');

      feature.reasons.forEach((reason) => {
        void Console.info(`MICRO_FRONTEND: Missing ${reason.type}`);
      });
    }
  }, [feature]);

  if (feature.isEnabled) {
    return <MicroFrontendContainer />;
  }

  return <ErrorPage type={ErrorPageType.AccessDenied} />;
};
