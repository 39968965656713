import AppConstants from '@hkm/constants/app.constants';
import { TFunction } from 'i18next';

import { CustomerContext, PropertyContext } from '@ac/library-api';
import { BrandingFaviconSettingValue } from '@ac/library-api/dist/businessContext/customer/settings/model/general/brandingFavicon';
import { getTranslation } from '@ac/library-utils/dist/utils/translations';

export interface AppFeatureActions {
  defaultLanguage: string;
  availableLanguages: string[];
  sessionIdleTimeoutInSeconds: number | undefined;
  sessionIdleTimeoutWarningInSeconds: number | undefined;
  brandingTabTitle: string;
  propertyBrandingFavicon: BrandingFaviconSettingValue | undefined;
  customerBrandingFavicon: BrandingFaviconSettingValue | undefined;
}

export const getAppFeature = (
  propertyContext: PropertyContext | undefined,
  customerContext: CustomerContext | undefined,
  i18n: {
    language: string;
    t: TFunction;
  }
): AppFeatureActions => {
  const propertySettings = propertyContext?.settings.processed;
  const customerSettings = customerContext?.settings.processed;
  const languages = { userLanguageCode: i18n.language };

  const propertyTabTitle = getTranslation(
    propertySettings?.general.brandingTabTitle ?? [],
    languages
  )?.content;

  const customerTabTitle = getTranslation(
    customerSettings?.general.brandingTabTitle ?? [],
    languages
  )?.content;

  return {
    defaultLanguage:
      propertySettings?.housekeeping.supportedHousekeepingMobileLanguages?.value
        .languageCode ?? AppConstants.FALLBACK_LANGUAGE,
    availableLanguages: propertySettings?.housekeeping
      .supportedHousekeepingMobileLanguages?.value.supportedLanguages ?? [
      AppConstants.FALLBACK_LANGUAGE,
    ],
    sessionIdleTimeoutInSeconds:
      propertySettings?.housekeeping.mobileSessionIdleTimeoutInSeconds,
    sessionIdleTimeoutWarningInSeconds:
      propertySettings?.housekeeping.mobileSessionIdleTimeoutWarningInSeconds,
    propertyBrandingFavicon: propertySettings?.general.brandingFavicon,
    customerBrandingFavicon: customerSettings?.general.brandingFavicon,
    brandingTabTitle: (
      propertyTabTitle ??
      customerTabTitle ??
      i18n.t('GLOBAL.TITLE')
    ).trim(),
  };
};
