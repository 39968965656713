import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { getCommunicationObject } from '@hkm/features/app/commands/initialization/acp/segments/getCommunicationObject';
import { getDeveloperTools } from '@hkm/features/app/commands/initialization/acp/segments/getDeveloperTools';
import { getUserUILanguage } from '@hkm/features/app/commands/initialization/acp/segments/getUserUILanguage';
import { useContainerToast } from '@hkm/features/app/commands/initialization/acp/segments/useContainerToast';
import { useMicroFrontendsContext } from '@hkm/features/microFrontends/store/context';
import { ACP } from '@hkm/publicApi';
import { mountRootParcel, registerApplication, start } from 'single-spa';

import { LoginService } from '@ac/library-utils/dist/services';

interface Output {
  create(): ACP;
}

export function useAcpObject(): Output {
  const { setNavBar } = useMicroFrontendsContext();
  const { createToastObject } = useContainerToast();
  const navigate = useNavigate();

  const create = useCallback((): ACP => {
    const communication = getCommunicationObject();
    const developerTools = getDeveloperTools(communication.eventBus);

    return {
      getUserUILanguage,
      businessContext: undefined,
      appsCache: {},
      communication,
      developerTools,
      api: {
        webSockets: undefined,
      },
      navigate,
      container: {
        getAccessToken: LoginService.accessToken,
        getIdToken: LoginService.idToken,
        toast: createToastObject(),
        setUpNavBar: setNavBar,
      },
      singleSpa: {
        registerApplication,
        start,
        mountRootParcel,
      },
      /**
       * getComponents - should be initialized after registration of all global providers
       * presenters - should be initialized after registration of all global providers
       */
    };
  }, [createToastObject, navigate, setNavBar]);

  return {
    create,
  };
}
