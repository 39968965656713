import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@hkm/features/app/App';
import { authenticate } from '@hkm/features/app/behaviours/authenticate';
import { initializeApi } from '@hkm/features/app/behaviours/initializeApi';
import { initializeSession } from '@hkm/features/app/behaviours/initializeSession';
import { initializeTranslations } from '@hkm/features/app/behaviours/initializeTranslations';
import { initializeWebComponents } from '@hkm/features/app/behaviours/initializeWebComponents';

import { initializeAppLikeBehaviour } from '@ac/mobile-components/dist/app-like-behaviour';

/**
 * This function should hold pre-react initialization
 */
(async () => {
  await authenticate();

  initializeWebComponents();
  initializeSession();
  initializeAppLikeBehaviour();
  initializeTranslations();
  initializeApi();

  const root = document.getElementById('root') as HTMLElement;
  const app = createRoot(root);

  app.render(<App root={root} />);
})();
