import { useCallback } from 'react';
import { useMenuPanelPresenterContext } from '@hkm/features/app/panels/menu/presenter/context';

interface Output {
  register(): void;
}

export function useRegisterPublicComponents(): Output {
  const menu = useMenuPanelPresenterContext();

  const register = useCallback((): void => {
    if (window.ACP) {
      window.ACP.getComponents = (): Promise<
        typeof import('@hkm/publicComponents/index')
      > => {
        return import('@hkm/publicComponents/index');
      };

      window.ACP.presenters = {
        menu,
      };
    }
  }, [menu]);

  return {
    register,
  };
}
