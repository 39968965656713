/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { MobileHeader } from '@hkm/features/app/features/header/Header';

import { Errors, IconName } from '@ac/web-components';

interface Props {
  message: string;
  description?: string;
}

export const MicroFrontendErrorPage = ({
  message,
  description,
}: Props): JSX.Element => {
  return (
    <ac-layout gridTemplate="'header''body'" gridAutoRows="min-content auto">
      <MobileHeader />

      <ac-layout-item area="body">
        <ac-box class="height-percentage-100 ac-padding-lg">
          <ac-error-page
            errorType={Errors.custom}
            error={{
              icon: IconName.alert,
              header: message,
              subheader: description,
            }}
          />
        </ac-box>
      </ac-layout-item>
    </ac-layout>
  );
};
