import { MicroFrontendError } from '@hkm/features/microFrontends/errors/MicroFrontendError';
import { PageNavBarProps } from '@hkm/publicApi/windowObject';

export interface MicroFrontendsState {
  navBar: PageNavBarProps | undefined;
  loading: boolean;
  error: MicroFrontendError | undefined;
}

export function getDefaultMicroFrontendsState(): MicroFrontendsState {
  return {
    navBar: undefined,
    loading: true,
    error: undefined,
  };
}
