import { useEffect } from 'react';
import { useRegisterPublicComponents } from '@hkm/features/app/commands/initialization/acp/useRegisterPublicComponents';

export function useInitPublicComponents() {
  const { register } = useRegisterPublicComponents();

  useEffect(() => {
    void register();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
