import { useEffect } from 'react';
import { useBrandingInitializationCommand } from '@hkm/features/app/features/branding/commands/useBrandingInitialization';

export function useBrandingInitializationBehaviour() {
  const { init } = useBrandingInitializationCommand();

  useEffect(() => {
    void init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
