/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { MobileHeaderSkeleton } from '@hkm/features/app/features/header/Header.skeleton';

import { SkeletonType } from '@ac/web-components';

export const MicroFrontendPageSkeleton = (): JSX.Element => {
  return (
    <ac-layout gridTemplate="'header''body'" gridAutoRows="min-content auto">
      <MobileHeaderSkeleton />

      <ac-layout-item area="body">
        <ac-box class="height-percentage-100 ac-padding-lg">
          <ac-box class="height-percentage-100 overflow-hidden position-relative border-default-radius">
            <ac-skeleton type={SkeletonType.covering} />
          </ac-box>
        </ac-box>
      </ac-layout-item>
    </ac-layout>
  );
};
