import { useCallback } from 'react';
import { updateFaviconInDOM } from '@hkm/features/app/features/branding/commands/actions/updateFaviconInDOM';
import { fetchFavicon } from '@hkm/features/app/features/branding/commands/api/fetchFavicon';
import { useMobileFeaturesContext } from '@hkm/features/app/store/features/context';
import { useActiveProperty } from '@hkm/features/property/useActiveProperty';

import { updateDocumentTitle } from '@ac/library-utils/dist/utils';

interface Output {
  init(): Promise<void>;
}

export function useBrandingInitializationCommand(): Output {
  const { app } = useMobileFeaturesContext();
  const { propertyId } = useActiveProperty();

  const init = useCallback(async () => {
    updateDocumentTitle(app.brandingTabTitle);

    const favicon = await fetchFavicon({
      propertyId,
      propertyBrandingFavicon: app.propertyBrandingFavicon,
      customerBrandingFavicon: app.customerBrandingFavicon,
    });

    if (favicon) {
      updateFaviconInDOM('#favicon-light', favicon);
      updateFaviconInDOM('#favicon-lg-light', favicon);
      updateFaviconInDOM('#favicon-dark', favicon);
      updateFaviconInDOM('#favicon-lg-dark', favicon);
    }
  }, [
    app.brandingTabTitle,
    app.customerBrandingFavicon,
    app.propertyBrandingFavicon,
    propertyId,
  ]);

  return {
    init,
  };
}
