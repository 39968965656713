/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { AppBranding } from '@hkm/features/app/features/branding/Branding';
import { useInitPublicComponents } from '@hkm/features/app/features/container/behaviours/useInitPublicComponents';
import { useOverrideBaseApiFromBusinessContext } from '@hkm/features/app/features/container/behaviours/useOverrideBaseApiFromBusinessContext';
import { AppRouter } from '@hkm/features/app/features/router/Router';

import { AcLayout } from '@ac/mobile-components/dist/components/layout';

export const AppLayout = (): JSX.Element => {
  useOverrideBaseApiFromBusinessContext();
  useInitPublicComponents();

  return (
    <AcLayout>
      <AppBranding />
      <AppRouter />
    </AcLayout>
  );
};
