/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */

import { AlignItems, CssUnit, SkeletonType } from '@ac/web-components';

export const MobileHeaderSkeleton = (): JSX.Element => {
  return (
    <ac-mobile-header class="bg-white">
      <ac-flex
        alignItems={AlignItems.center}
        class="height-percentage-100 ac-gap-lg ac-spacing-left-lg"
        slot="headerLeftSlot"
      >
        <ac-skeleton
          type={SkeletonType.custom}
          customSize={{
            width: {
              count: 19,
              unit: CssUnit.px,
            },
            height: {
              count: 19,
              unit: CssUnit.px,
            },
          }}
        />
        <ac-skeleton type={SkeletonType.text} />
      </ac-flex>
    </ac-mobile-header>
  );
};

export default MobileHeaderSkeleton;
