/** @jsxImportSource @ac/library-utils/dist/web-components/wc-jsx */
import { memo } from 'react';
import { Navbar } from '@hkm/features/app/features/navbar/Navbar';
import { useMicroFrontendInitialization } from '@hkm/features/microFrontends/behaviours/useMicroFrontendInitialization';
import { MicroFrontendPageSkeleton } from '@hkm/features/microFrontends/pages/container/features/container/Container.skeleton';
import { MicroFrontendErrorPage } from '@hkm/features/microFrontends/pages/container/features/error/Error';
import { useMicroFrontendsContext } from '@hkm/features/microFrontends/store/context';
import classNames from 'classnames';

import { AlignItems, JustifyContent } from '@ac/mobile-components/dist/enums';
import { Errors, FlexDirection, IconName } from '@ac/web-components';

export const MicroFrontendContainerDefinition = (): JSX.Element => {
  const { state } = useMicroFrontendsContext();

  useMicroFrontendInitialization();

  return (
    <>
      {state.navBar && !state.loading && <Navbar />}
      <ac-flex
        grow={true}
        direction={FlexDirection.column}
        justifyContent={JustifyContent.center}
        alignItems={AlignItems.center}
        class="overflow-hidden"
      >
        {state.navBar ? (
          <>
            {state.loading && <ac-loader-covering />}
            {state.error && (
              <ac-error-page
                errorType={Errors.custom}
                error={{
                  icon: IconName.alert,
                  header: state.error.message,
                  subheader: state.error.description,
                }}
              />
            )}
          </>
        ) : (
          <>
            {state.loading && <MicroFrontendPageSkeleton />}
            {state.error && (
              <MicroFrontendErrorPage
                message={state.error.message}
                description={state.error.description}
              />
            )}
          </>
        )}
        <div
          className={classNames(
            'height-percentage-100',
            'width-percentage-100',
            'module-app',
            {
              hidden: state.error || state.loading,
            }
          )}
        />
      </ac-flex>
    </>
  );
};

export const MicroFrontendContainer = memo(MicroFrontendContainerDefinition);
